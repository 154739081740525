import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LinkOrButton from 'components/consumer/LinkOrButton';
import IconJoybirdSymbol from 'components/icons/iconJoybirdSymbol';
import IconArrowDownSimple from 'components/icons/iconArrowDownSimple';
import { colorBrand, colorGray } from 'styles/layout/colors';
import useKeyboardControlForDropdown from 'hooks/useKeyboardControlForDropdown';

const DesktopTopNavList = ({
  items = [],
  column = 'left',
  isShopByOpen = false,
  setIsShopByLinkHovered,
}) => {
  const toggleContainerRef = useRef(null);
  const toggleRef = useRef(null);
  const [isShopByElementHovered, setIsShopByElementHovered] = useState(false);
  const shopByTimerRef = useRef(null);

  useEffect(() => {
    // Use a timer to delay the opening of the Shop By dropdown so that the user
    // can move their cursor over it without the dropdown opening immediately

    if (isShopByElementHovered) {
      shopByTimerRef.current = window.setTimeout(() => {
        setIsShopByLinkHovered?.(true);
      }, 250);
    } else {
      if (shopByTimerRef.current) {
        window.clearTimeout(shopByTimerRef.current);
      }

      setIsShopByLinkHovered?.(false);
    }

    return () => {
      if (shopByTimerRef.current) {
        window.clearTimeout(shopByTimerRef.current);
      }
    };
  }, [isShopByElementHovered, setIsShopByLinkHovered]);

  useKeyboardControlForDropdown({
    isOpen: isShopByOpen,
    // Since the popup container is in a separate component, while not ideal it
    // is simplest to access this DOM element using vanilla JS
    getPopupContainerElement: () =>
      document.querySelector('[data-xc="DesktopNavShopByMenu"]'),
    toggleContainerRef,
    toggleRef,
    open: () => {
      setIsShopByLinkHovered?.(true);
    },
    close: () => {
      setIsShopByLinkHovered?.(false);
    },
  });

  return (
    <ul
      ref={toggleContainerRef}
      className={classNames(
        'flex-row items-center flex list-none m-0 p-0 w-[280px]',
        {
          'justify-self-start justify-start relative left-[-.5rem]':
            column === 'left',
          'justify-self-end justify-end relative right-[-.5rem]':
            column === 'right',
        }
      )}
    >
      {items.map(({ id, label, url, icon, onClick }) => {
        const isShopByItem = id === 'shop-by';

        return (
          <li
            key={id}
            className="items-center flex list-none m-0 p-0 justify-center text-center"
          >
            <LinkOrButton
              to={url}
              className="group/DesktopTopNavList__item-link text-xs items-center border-0 rounded-none text-gray flex flex-1 p-2 no-underline whitespace-nowrap hover:cursor-pointer focus:underline hover:underline focus-visible:outline-offset-[-2px] bg-transparent"
              onClick={e => {
                onClick?.(e);

                if (isShopByItem) {
                  setIsShopByLinkHovered?.(!isShopByOpen);
                }
              }}
              onMouseEnter={() => {
                if (isShopByItem) {
                  setIsShopByElementHovered(true);
                }
              }}
              onMouseLeave={() => {
                if (isShopByItem) {
                  setIsShopByElementHovered(false);
                }
              }}
              ref={isShopByItem ? toggleRef : undefined}
            >
              {icon === 'IconJoybirdSymbol' && (
                <IconJoybirdSymbol
                  width={18}
                  height={13}
                  color={colorBrand}
                  className="transition-[transform_.25s_ease-in-out] mr-2"
                />
              )}

              <span>{label}</span>

              {icon === 'dropdown-arrow' && (
                <IconArrowDownSimple
                  color={colorGray}
                  className={classNames(
                    'transition-[transform_.25s_ease-in-out] ml-2',
                    'group-hover/DesktopTopNavList__item-link:rotate-180 group-hover/DesktopTopNavList__item-link:origin-center',
                    {
                      'rotate-180 origin-center': isShopByOpen,
                    }
                  )}
                />
              )}
            </LinkOrButton>
          </li>
        );
      })}
    </ul>
  );
};

DesktopTopNavList.propTypes = {
  column: PropTypes.oneOf(['left', 'right']),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      label: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  isShopByOpen: PropTypes.bool,
  setIsShopByLinkHovered: PropTypes.func,
};

export default DesktopTopNavList;
