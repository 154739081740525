import React from 'react';
import PropTypes from 'prop-types';

import FullWidthContainer from 'components/consumer/FullWidthContainer';
import DesktopTopNavList from 'components/nav/DesktopHeader/components/DesktopTopNavList';

const DesktopTopNavUI = ({
  leftItems = [],
  rightItems = [],
  importantMessage,
  isShopByOpen,
  setIsShopByLinkHovered,
}) => (
  <FullWidthContainer
    className="items-center bg-gray-light7 flex flex-row justify-between"
    data-xc="DesktopTopNav"
    hasTopNavPadding
  >
    <DesktopTopNavList
      items={leftItems}
      column="left"
      isShopByOpen={isShopByOpen}
      setIsShopByLinkHovered={setIsShopByLinkHovered}
    />

    <div className="text-xs justify-self-center flex-1 font-bold text-gray px-4 py-2 text-center [&_p]:m-0 [&_a]:text-brand [&_a]:no-underline [&_a:focus]:underline [&_a:hover]:underline [&_a:focus-visible]:focus-visible:outline-offset-0 [&_u]:sr-only [&_u]:no-underline">
      {importantMessage}
    </div>

    <DesktopTopNavList items={rightItems} column="right" />
  </FullWidthContainer>
);

DesktopTopNavUI.propTypes = {
  leftItems: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  importantMessage: PropTypes.node,
  isShopByOpen: PropTypes.bool,
  rightItems: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  setIsShopByLinkHovered: PropTypes.func,
};

export default DesktopTopNavUI;
