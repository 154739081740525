import React, { useState } from 'react';
import classNames from 'classnames';
import { useInterval } from 'react-use';

const DesktopTopNavTextBanner = ({ bannerItems = [] }) => {
  const [index, setIndex] = useState(0);

  useInterval(() => {
    setIndex(prevIndex =>
      prevIndex !== bannerItems.length - 1 ? prevIndex + 1 : 0
    );
  }, 7000);

  return (
    <div className="relative whitespace-nowrap">
      {bannerItems?.map((item, itemIndex) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={itemIndex}
          className={classNames(
            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-100 transition-all ease-in-out duration-500',
            {
              '!opacity-0': index !== itemIndex,
            }
          )}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default DesktopTopNavTextBanner;
