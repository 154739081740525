import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';

import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import usePostHogFeatureFlagWithPayload from 'hooks/usePostHogFeatureFlag/usePostHogFeatureFlagWithPayload';
import useContentfulPromotionContent from 'hooks/useContentfulPromotionContent';
import DesktopNavClearance from 'components/nav/DesktopHeader/components/DesktopNavClearance';
import SanitizedHtml from 'components/consumer/SanitizedHtml';
import desktopStaticNavItems from './desktopStaticNavItems';
import newDesktopStaticNavItems from './newDesktopStaticNavItems';

const useDesktopNavItems = () => {
  const categoryNavTest = usePostHogFeatureFlag('navbar-categories-vs-rooms');
  const [
    customSaleNavMenuFeatureFlag,
    customSaleNavMenuFeatureFlagPayload,
  ] = usePostHogFeatureFlagWithPayload('customSaleNavMenu');

  const staticItems =
    categoryNavTest === 'test'
      ? newDesktopStaticNavItems
      : desktopStaticNavItems;

  const { promotionData } = useContentfulPromotionContent();

  const salesMenuOverrides =
    customSaleNavMenuFeatureFlag === 'customized'
      ? {
          url: customSaleNavMenuFeatureFlagPayload?.url,
          ctaUrl: customSaleNavMenuFeatureFlagPayload?.ctaUrl,
          items: customSaleNavMenuFeatureFlagPayload?.items,
        }
      : customSaleNavMenuFeatureFlag === 'contentful'
      ? {
          url: promotionData?.saleDropdownAuxPromoUrl,
          ctaUrl: promotionData?.saleDropdownAuxPromoUrl,
        }
      : {};

  const categoryNavItems = useMemo(() => {
    const updatedItems = cloneDeep(staticItems);

    const joybirdDesignHouseGroup = updatedItems.find(
      item => item.id === 'joybird-design-house'
    );

    const freeDesignServicesItem = joybirdDesignHouseGroup
      ? joybirdDesignHouseGroup.items.find(
          item => item.id === 'free-design-services'
        )
      : null;

    if (freeDesignServicesItem) {
      freeDesignServicesItem.label = 'Free Design Services';
    }

    const saleCategory = updatedItems.find(({ id }) => id === 'sale');

    if (saleCategory) {
      saleCategory.image =
        promotionData?.saleDropdownMedia || saleCategory.image;
    }

    const flashSaleItem = promotionData?.displayFlashSale
      ? {
          label: 'Flash Sale',
          id: 'flash-sale',
          url: '/flash-sale/',
        }
      : null;

    saleCategory.url = salesMenuOverrides?.url ?? '/sale/';
    saleCategory.ctaUrl = salesMenuOverrides?.ctaUrl ?? '/sale/';

    saleCategory.items = [
      ...(salesMenuOverrides?.items ?? []),
      {
        label: 'Clearance',
        id: 'clearance',
        url: '/clearance/',
        customContent: <DesktopNavClearance />,
      },
      flashSaleItem,
    ].filter(item => !!item);

    if (
      promotionData?.saleDropdownAuxPromoText &&
      promotionData?.saleDropdownAuxPromoUrl
    ) {
      saleCategory.items = [
        {
          label: (
            <SanitizedHtml
              as="span"
              html={promotionData?.saleDropdownAuxPromoText}
            />
          ),
          id: 'aux-promo-link',
          url: `${promotionData?.saleDropdownAuxPromoUrl}`,
        },
        ...saleCategory.items,
      ];
    }

    return updatedItems;
  }, [
    promotionData?.displayFlashSale,
    promotionData?.saleDropdownAuxPromoText,
    promotionData?.saleDropdownAuxPromoUrl,
    promotionData?.saleDropdownMedia,
    staticItems,
    salesMenuOverrides?.url,
    salesMenuOverrides?.ctaUrl,
    salesMenuOverrides?.items,
  ]);

  return categoryNavItems;
};

export default useDesktopNavItems;
